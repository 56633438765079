import {
  UploadedFiles,
  centerAspectCrop,
  getCroppedImageData,
  makeBase64,
} from "@Services/fileUploadService";
import { Box, styled } from "@mui/material";

import { useRef, useState } from "react";
import ReactCrop, { PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import Modal, { ModalTypes } from "./Modal";

export default function ImageCropperModal({
  modalRef,
  data,
  handleUploadFiles,
}: ModalTypes & CropperModalProps) {
  const [crop, setCrop] = useState<PixelCrop>();
  const imageRef = useRef<HTMLImageElement | undefined>();

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    const { width, height } = e.currentTarget;
    setCrop(centerAspectCrop(height, width, 1));
  }

  async function handleCrop() {
    if (crop?.height === 0 && crop?.width === 0)
      return toast.error("Please Select the Area To Crop");
    const croppedData = await getCroppedImageData(
      imageRef.current,
      crop as PixelCrop,
      data?.files?.[0]?.file?.name as string
    );

    handleUploadFiles(
      [
        {
          ...data.files[0],
          url: croppedData?.imageUrl,
        },
      ],
      data.filesInArray
    );
  }

  return (
    <Modal
      onSuccess={handleCrop}
      modalRef={modalRef}
      closeOnSuccess
      customBody={
        <Box sx={{ width: "100%" }}>
          <ReactCrop
            style={{ width: "100%" }}
            aspect={1}
            ruleOfThirds
            crop={crop}
            onChange={(pixelCrop) => setCrop(pixelCrop)}
          >
            <CropperImage
              ref={imageRef}
              onLoad={onImageLoad}
              src={data?.files?.[0]?.url}
            />
          </ReactCrop>
        </Box>
      }
    ></Modal>
  );
}

export type CropperModalProps = {
  data: CropperModalDataType;
  handleUploadFiles: (
    withUrlFiles: ReturnType<typeof makeBase64>,
    filesInArray: File[]
  ) => void;
};

export type CropperModalDataType = {
  files: UploadedFiles[];
  filesInArray: File[];
};

const CropperImage = styled("img")({
  width: "100%",
});
