import InputField from "@Components/InputField/InputField";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import Modal, { ModalRefType } from "./Modal";
import ProfileUploadContainer from "@Components/ProfileUploadContainer/ProfileUploadContainer";
import Profile from "@Assets/Profile.png";
function EditProfileModal({
  modalRef,
}: {
  modalRef: React.MutableRefObject<ModalRefType>;
}) {
  const { control, watch, handleSubmit } = useForm({
    mode: "all",
    defaultValues: {
      firstName: "Cameron",
      lastName: "Williamson",
      email: "cameronwilliamson@domain.com",
      image: [
        {
          url: Profile,
        },
      ],
    },
  });
  function handleSuccess() {
    console.log("Logout");
  }
  return (
    <Modal
      showClose
      customBody={
        <Box mb={3} textAlign="center">
          <Typography sx={{ fontWeight: "500", fontSize: 20, mb: 4 }}>
            Edit Profile
          </Typography>
          <ProfileUploadContainer
            avatarSize={156}
            control={control}
            watch={watch}
          />
          <InputField
            control={control}
            name="firstName"
            label="First Name"
            placeholder="Enter First Name"
            sx={{ mt: 3, mb: 2 }}
          />
          <InputField
            control={control}
            name="lastName"
            label="Last Name"
            placeholder="Enter Last Name"
            sx={{ mb: 2 }}
          />
          <InputField
            control={control}
            name="email"
            label="Email"
            placeholder="Enter Email"
            sx={{ mb: 2 }}
            disabled
          />
        </Box>
      }
      isForm
      showCancel={false}
      modalRef={modalRef}
      onSuccess={handleSubmit(handleSuccess)}
      okText="Update Profile"
    />
  );
}

export default EditProfileModal;
