import { Palette } from "@mui/material";

declare module "@mui/material/styles" {
  interface Palette {
    custom: {
      errorFilter: string;
      dark: string;
      placeholder: string;
      main: string;
      appGradient: string;
      font1: string;
      font2: string;
      bgInput: string;
      background: string;
      boxShadow: string;
    };
  }

  interface PaletteOptions {
    custom?: {
      errorFilter: string;
      dark: string;
      placeholder: string;
      main: string;
      appGradient: string;
      font1: string;
      font2: string;
      bgInput: string;
      background: string;
      boxShadow: string;
    };
  }
}

export default {
  primary: {
    main: "#8036CA", 
    contrastText: "#444444",
    light: "#F2F2F2",
    dark: "#444444",
  },
  secondary: {
    main: "#E9E5DF",
    light: "#EEEEEEEE",
    contrastText: "#666666",
  },
  text: {
    primary: "#011628",
    secondary: "#666666",
  },
  custom: {
    dark: "#444444",
    placeholder: "#BABABA",
    main: "#8036CA", 
    errorFilter:
      "invert(14%) sepia(78%) saturate(6511%) hue-rotate(0deg) brightness(100%) contrast(118%)",
    appGradient: "linear-gradient(322.69deg, #8036CA -0.67%, #8036CA 99.11%)", 
    font1: "#333333",
    font2: "#1E1E1E",
    bgInput: "#FAFAFA",
    background: "#F5FAFF",
    boxShadow: "#0000000F",
  },
  common: {
    white: "#FFFFFF",
    black: "#000000",
  },
  mode: "light",
  success: {
    main: "#0B8235",
  },
  error: {
    main: "#ff0000",
  },
} as Palette;