import {
  Box,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import DropdownIcon from "@Assets/icons/Dropdown.svg";

import Logout from "@Assets/icons/Logout.svg";
import ListRenderer from "@Components/ListRenderer/ListRenderer";
import ChangePasswordModal from "@Components/Modal/ChangePasswordModal";
import ConfirmationModal from "@Components/Modal/ConfirmationModal";
import EditProfileModal from "@Components/Modal/EditProfileModal";
import { ModalRefType } from "@Components/Modal/Modal";
import { GET_SETTING_ACTIONS_LIST, SETTING_ACTIONS } from "@Constants/app";
import { useAuthStore } from "@Store/authStore";
import { CenteredBox } from "@Theme/GeneralStyledComponents";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileAvatar } from "./Styles";

export default function Settings() {
  const navigate = useNavigate();

  const anchorElRef = useRef<HTMLButtonElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const logoutModalRef = useRef<ModalRefType>();
  const changePasswordModalRef = useRef<ModalRefType>();
  const editProfileModalRef = useRef<ModalRefType>();
  const [userDetails, setUserDetails] = useState<any>({});
  const [profilePhoto, setProfilePhoto] = useState<string | null>("");

  useEffect(() => {
    const data = localStorage.getItem("user");
    if (data) {
      setUserDetails(JSON.parse(data));
    }
  }, []);

  function handleClose() {
    setAnchorEl(null);
  }

  const handleItemClick = (type: number, link?: string) => () => {
    if (link) {
      navigate(link);
      return;
    }
    switch (type) {
      case SETTING_ACTIONS.LOGOUT:
        logoutModalRef.current?.open();
        break;
      default:
        break;
    }

    handleClose();
  };

  function handleLogout() {
    logoutModalRef.current?.close();
    localStorage.clear();
    useAuthStore.getState().setUserAuthentication(false);
  }

  function handleOpenSettings() {
    setAnchorEl(anchorElRef.current);
  }
  return (
    <>
      <Box display="flex" alignItems="center" gap={2}>
        <Box
          sx={{ cursor: "pointer" }}
          display="flex"
          alignItems="center"
          onClick={handleOpenSettings}
        >
          <CenteredBox gap={1}>
            <ProfileAvatar
              sx={{
                height: 32,
                width: 32,
                fontSize: 14,
              }}
              src={""}
            >
              {userDetails?.displayName
                ?.toUpperCase()
                .split(" ")
                .map((name: string) => name[0])
                .join("")}
            </ProfileAvatar>
            <Typography
              color="text.secondary"
              display={{ xs: "none", sm: "block" }}
            >
              {userDetails?.displayName}
            </Typography>
          </CenteredBox>
          <IconButton disableRipple ref={anchorElRef}>
            <DropdownIcon />
          </IconButton>
        </Box>

        <Menu
          open={Boolean(anchorEl)}
          sx={{
            "& .MuiPaper-root": {
              mt: 2,
              minWidth: 150,
              borderRadius: 2,
            },
            "& .MuiList-root": {
              paddingTop: 0,
              paddingBottom: 0,
            },
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
        >
          <ListRenderer
            data={GET_SETTING_ACTIONS_LIST()}
            renderItem={({ id, link, title }) => (
              <MenuItem
                sx={{
                  "&:first-of-type": { pt: 1 },
                  "&:last-of-type": { pb: 1 },
                }}
                key={id}
                onClick={handleItemClick(id, link)}
              >
                <ListItemText
                  sx={{
                    color: id == SETTING_ACTIONS.LOGOUT ? "red" : "",
                  }}
                >
                  {title}
                </ListItemText>
              </MenuItem>
            )}
          />
        </Menu>
      </Box>
      <ConfirmationModal
        modalRef={logoutModalRef}
        onSuccess={handleLogout}
        title="Logout"
        description="Are you sure you want to logout?"
        okText="Logout"
        cancelText="No"
        icon={<Logout />}
      />
      <ChangePasswordModal modalRef={changePasswordModalRef} />
      <EditProfileModal modalRef={editProfileModalRef} />
    </>
  );
}
