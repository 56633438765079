import { request } from "@Api/ApiRequestHandler";
import { InitDocumentResponse } from "@Api/ResponseTypes";
import { UploadedFile } from "@Components/Inputs/FileUpload";
import { API_MODES } from "@Constants/api";
import { API_ROUTES } from "@Constants/apiUrls";
import { FileTypes } from "@Constants/enums";
import dayjs from "dayjs";
import { UseFormReturn } from "react-hook-form";
import { PixelCrop, centerCrop, makeAspectCrop } from "react-image-crop";
import { toast } from "react-toastify";

const canvas = document.createElement("canvas");

async function uploadOnAzure(file: File, sasUrl: string) {
  const response = await fetch(sasUrl, {
    method: "PUT",
    body: file,
    headers: {
      "x-ms-blob-type": "BlockBlob",
      "Content-Type": "application/octet-stream",
    },
  });
  return response;
}

function deleteFile({ indexToDel, setField, fieldName, value }: DelFileProps) {
  if (indexToDel !== 0 && !indexToDel) return setField(fieldName, []);

  const filteredFiles = value.filter((_file, index) => index !== indexToDel);
  setField(fieldName, filteredFiles);
}

function makeBase64(files: File[]) {
  return files.map((file, index) => ({
    url: URL.createObjectURL(file),
    name: file.name,
    size: getSizeToShow(file.size),
    file,
    id: `${dayjs().unix()}${index}`,
    fileType: file.type.split("/")[0],
  }));
}

function getSizeToShow(size: number) {
  const sizeInKb = size / 1024;
  if (sizeInKb < 1024) {
    return `${sizeInKb.toFixed(2)} KB`;
  }
  const sizeInMb = sizeInKb / 1024;
  return `${sizeInMb.toFixed(2)} MB`;
}

export async function fileUploadCall(file: File) {
  const [fileType, extension] = file.type.split("/");
  const payload = {
    name: file.name,
    size: file.size,
    extension: `.${extension}`,
    isPrivate: false,
    fileType: FileTypes[fileType],
  };
  try {
    const { data } = await request<InitDocumentResponse>({
      method: API_MODES.POST,
      url: API_ROUTES.DOCUMENT.INIT_UPLOAD,
      params: payload,
    });

    await uploadOnAzure(file, data?.sasUrl);

    return data;
  } catch (error) {
    return error;
  }
}

function handleChangeUploadingState({
  value,
  onChange,
  data,
  id,
}: {
  value: UploadedFiles[];
  onChange: (value: UploadedFiles[]) => void;
  data?: any;
  id: number;
}) {
  if (!data) {
    // @ts-ignore
    value[id].error = true;
  }

  value[id].uploading = false;
  value[id].MediaId = data?.documentId;
  onChange(value);
}

function checkFileValidity({
  filesInArray,
  value,
  limit = 0,
  allowedFileTypes,
  withoutExtension = false,
  allowOnly,
}: CheckFileValidityProps) {
  if (
    Boolean(limit) &&
    (filesInArray.length > limit ||
      (value?.length ?? 0) + filesInArray.length > limit)
  ) {
    toast.error(`You can upload only ${limit} files`);
    return false;
  }

  if (
    allowOnly &&
    !filesInArray.every(
      (file: { [key: string]: any }) => file.type.split("/")[0] === allowOnly
    )
  ) {
    toast.error(`Invalid File Type, only ${allowOnly} is allowed `);
    return false;
  }

  if (
    allowedFileTypes &&
    !filesInArray.every((file: { [key: string]: any }) =>
      allowedFileTypes?.includes(
        withoutExtension ? file.type.split("/")[0] : file.type
      )
    )
  ) {
    toast.error(
      `Invalid File Type  only ${allowedFileTypes
        .map((type) => type.split("/")[1])
        .join(", ")} files are allowed `
    );
    return false;
  }
  return true;
}

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop({ unit: "px" }, aspect, mediaWidth, mediaHeight),
    mediaWidth,
    mediaHeight
  );
}

async function cropImage(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  fileName: string
) {
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    toast.error("Image crop was not successful please try again ");
    return;
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const croppedWidth = crop.width * scaleX;
  const croppedHeight = crop.height * scaleY;
  canvas.width = croppedWidth;
  canvas.height = croppedHeight;

  ctx.imageSmoothingQuality = "high";
  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  ctx.drawImage(
    image,
    cropX,
    cropY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    croppedWidth,
    croppedHeight
  );

  let imageBlobObj: any = await new Promise((resolve) =>
    canvas.toBlob(resolve)
  );
  let fileObject: File = new File([imageBlobObj], fileName, {
    type: imageBlobObj.type,
  });
  let imageUrl: string = URL.createObjectURL(imageBlobObj);

  ctx.restore();

  return { imageUrl, fileObject };
}

async function getCroppedImageData(
  image: HTMLImageElement | undefined,
  crop: PixelCrop,
  fileName: string
) {
  if (!image) {
    toast.error("Image crop was not successful please try again");
    return null;
  }
  return await cropImage(image, canvas, crop, fileName);
}

type DelFileProps = {
  fieldName: string;
  value: UploadedFile[];
  setField: UseFormReturn<any>["setValue"];
  indexToDel?: number;
};

type CheckFileValidityProps = {
  filesInArray: { [key: string]: any }[];
  value?: UploadedFile[];
  limit?: number;
  withoutExtension?: boolean;
  allowedFileTypes?: string[];
  allowOnly?: string;
};

export type UploadedFiles = {
  url?: string;
  file?: File;
  MediaId?: number;
  uploading?: boolean;
};

export {
  checkFileValidity,
  deleteFile,
  handleChangeUploadingState,
  makeBase64,
  centerAspectCrop,
  getCroppedImageData,
};
