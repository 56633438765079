import AppLogo from "@Assets/icons/AppLogo.svg";
import FullPageLoader from "@Components/FullPageLoader/FullPageLoader";
import { NavigationRoutes } from "@Navigation/NavigationRoutes";
import { setPageTitle } from "@Utils/utils";
import { Box } from "@mui/material";
import { Suspense, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Content from "./Content/Content";
import Topbar from "./Topbar/Topbar";

export default function AuthenticatedAppRoot() {
  const { pathname } = useLocation();

  useEffect(() => {
    setPageTitle(pathname);
  }, [pathname]);

  return (
    <Suspense fallback={<FullPageLoader width="100%" />}>
      <Box display="flex" height="100dvh">
        <Topbar />
        <Content />
      </Box>
    </Suspense>
  );
}

export function AppLogoContainer() {
  return (
    <Box
      component="a"
      href="https://www.referencedbible.com"
      target="_blank"
      rel="noopener noreferrer"
      flexGrow={1}
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <AppLogo height={42} width={117} />
      </Box>
    </Box>
  );
}
