import { FileTypeEnums, SocialLinkEnums } from "./enums";
import Facebook from "@Assets/icons/Social/Facebook.png";
import Instagram from "@Assets/icons/Social/Instagram.png";
import Twitter from "@Assets/icons/Social/Twitter.png";
import Snapchat from "@Assets/icons/Social/Snapchat.png";
import Threads from "@Assets/icons/Social/Threads.png";
import Youtube from "@Assets/icons/Social/Youtube.png";
import Tiktok from "@Assets/icons/Social/Tiktok.png";
import LinkedIn from "@Assets/icons/Social/Linkedin.png";
import GoogleMaps from "@Assets/icons/Social/GoogleMaps.png";

export const TAB_SIZE = "980px";
export const DRAWER_WIDTH = "270px";
export const DRAWER_WIDTH_NUMBER = 270;
export const CLOSE_SIDEBAR_ON = "xl";
export const TABLE_MIN_WIDTH_ON_MOBILE = 200;
export const TABLE_MIN_WIDTH_BREAKPOINT = 768;
export const APP_NAME = "The Referenced Bible";

export const SocialLinkRegex = {
  Facebook: /^https:\/\/www\.facebook.com/,
  Instagram: /^https:\/\/www\.instagram.com/,
  Twitter: /^https:\/\/www\.twitter.com/,
  Snapchat: /^https:\/\/www\.snapchat.com/,
  Threads: /^https:\/\/www\.threads.com/,
  Youtube: /^https:\/\/www\.youtube.com/,
  Tiktok: /^https:\/\/www\.tiktok.com/,
  LinkedIn: /^https:\/\/www\.linkedin.com/,
  GoogleMaps: /^https:\/\/www\.google.com/,
};

export const enum SETTING_ACTIONS {
  LOGOUT = 1,
  CHANGE_PASSWORD = 2,
  EDIT_PROFILE = 3,
  SUBSCRIPTION = 4,
  PAYMENT = 5,
}

export const GET_SETTING_ACTIONS_LIST = (): {
  id: number;
  title: string;
  link?: string;
}[] => [
  {
    id: SETTING_ACTIONS.LOGOUT,
    title: "Sign Out",
    //add saved verses
  },
];

export const AllowedFileTypes = {
  [FileTypeEnums.Image]: ["image/png", "image/jpeg", "image/jpg"],
  [FileTypeEnums.Video]: ["video/mp4"],
  [FileTypeEnums.Application]: ["application/pdf"],
};

export const SocialLinks = [
  {
    label: "Google Maps",
    value: SocialLinkEnums.GoogleMaps,
    regex: SocialLinkRegex.GoogleMaps,
  },
  {
    label: "Instagram",
    value: SocialLinkEnums.Instagram,
    regex: SocialLinkRegex.Instagram,
  },
  {
    label: "Facebook",
    value: SocialLinkEnums.Facebook,
    regex: SocialLinkRegex.Facebook,
  },
  {
    label: "X",
    value: SocialLinkEnums.Twitter,
    regex: SocialLinkRegex.Twitter,
  },
  {
    label: "TikTok",
    value: SocialLinkEnums.Tiktok,
    regex: SocialLinkRegex.Tiktok,
  },
  {
    label: "Snapchat",
    value: SocialLinkEnums.Snapchat,
    regex: SocialLinkRegex.Snapchat,
  },
  {
    label: "Youtube",
    value: SocialLinkEnums.Youtube,
    regex: SocialLinkRegex.Youtube,
  },
  {
    label: "Threads",
    value: SocialLinkEnums.Threads,
    regex: SocialLinkRegex.Threads,
  },
  {
    label: "LinkedIn",
    value: SocialLinkEnums.LinkedIn,
    regex: SocialLinkRegex.LinkedIn,
  },
];

export const SocialLinksMap = {
  [SocialLinkEnums.Facebook]: { name: "Facebook", icon: Facebook },
  [SocialLinkEnums.Instagram]: { name: "Instagram", icon: Instagram },
  [SocialLinkEnums.Twitter]: { name: "Twitter", icon: Twitter },
  [SocialLinkEnums.Snapchat]: { name: "Snapchat", icon: Snapchat },
  [SocialLinkEnums.Threads]: { name: "Threads", icon: Threads },
  [SocialLinkEnums.Youtube]: { name: "Youtube", icon: Youtube },
  [SocialLinkEnums.Tiktok]: { name: "Tiktok", icon: Tiktok },
  [SocialLinkEnums.LinkedIn]: { name: "LinkedIn", icon: LinkedIn },
  [SocialLinkEnums.GoogleMaps]: { name: "Google Maps", icon: GoogleMaps },
};
