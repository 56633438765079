import InputField from "@Components/InputField/InputField";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import Modal, { ModalRefType } from "./Modal";

function ChangePasswordModal({
  modalRef,
}: {
  modalRef: React.MutableRefObject<ModalRefType>;
}) {
  const { control, handleSubmit } = useForm({
    mode: "all",
  });
  function handleSuccess() {
    console.log("Logout");
  }
  return (
    <Modal
      showClose
      customBody={
        <Box mb={3} textAlign="center">
          <Typography sx={{ fontWeight: "500", fontSize: 20, mb: 4 }}>
            Change Password
          </Typography>
          <InputField
            control={control}
            name="oldPassword"
            label="Old Password"
            placeholder="Enter Old Password"
            sx={{ mb: 2 }}
            type="password"
          />
          <InputField
            control={control}
            name="newPassword"
            label="New Password"
            placeholder="Enter New Password"
            sx={{ mb: 2 }}
            type="password"
          />
          <InputField
            control={control}
            name="confirmPassword"
            label="Confirm Password"
            placeholder="Enter Confirm Password"
            sx={{ mb: 2 }}
            type="password"
          />
        </Box>
      }
      isForm
      showCancel={false}
      modalRef={modalRef}
      onSuccess={handleSubmit(handleSuccess)}
      okText="Update Password"
    />
  );
}

export default ChangePasswordModal;
