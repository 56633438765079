import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import VerseReferences from '../CrossReference/CrossReference';

function ChapterView() {
  const { book, chapter } = useParams<{ book: string; chapter: string }>();
  const [verses, setVerses] = useState<any[]>([]);
  const [selectedVerse, setSelectedVerse] = useState<string | null>(null);

  useEffect(() => {
    const fetchVerses = async () => {
      try {
        const response = await axios.get(`https://bible-api.com/${book}+${chapter}`);
        setVerses(response.data.verses);
      } catch (error) {
        console.error('Error fetching verses:', error);
      }
    };
    fetchVerses();
  }, [book, chapter]);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {`${book} Chapter ${chapter}`}
      </Typography>
      <List>
        {verses.map((verse) => (
          <ListItem key={verse.verse} alignItems="flex-start">
            <ListItemText
              primary={`Verse ${verse.verse}`}
              secondary={verse.text}
            />
            <Button variant="outlined" onClick={() => setSelectedVerse(`${book} ${chapter}:${verse.verse}`)}>
              References
            </Button>
          </ListItem>
        ))}
      </List>

      {selectedVerse && (
        <div className="modal">
          <div className="modal-content">
            <span onClick={() => setSelectedVerse(null)} className="close-button">
              &times;
            </span>
            <VerseReferences verse={selectedVerse} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ChapterView;