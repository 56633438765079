import InputField from "@Components/InputField/InputField";
import { Avatar, Badge, Box, CircularProgress } from "@mui/material";
import FileUpload, { FileUploadTypes } from "@Components/Inputs/FileUpload";
import CameraIcon from "@Assets/icons/Camera.svg";
import { UseFormReturn } from "react-hook-form";
import { CenteredBox } from "@Theme/GeneralStyledComponents";

export default function ProfileUploadContainer({
  control,
  watch,
  name = "image",
  avatarSize = 200,
}: ProfileUploadContainerProps) {
  const avatarSizeObj = { xs: avatarSize / 1.5, xl: avatarSize };
  const file = watch(name);
  const isLoading = file?.[0]?.isLoading;
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      badgeContent={
        <InputField<FileUploadTypes>
          component={FileUpload}
          name={name}
          control={control}
          showDefault={false}
        >
          <Box component={CameraIcon} width={{ xs: 40, xl: 50 }} />
        </InputField>
      }
    >
      <CenteredBox
        width={avatarSizeObj}
        height={avatarSizeObj}
        border="2px solid"
        borderRadius="50%"
        borderColor="common.white"
        boxShadow="0px 4px 4px 0px #00000026"
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Avatar
            src={file?.[0]?.url}
            sx={{
              width: avatarSizeObj,
              height: avatarSizeObj,
            }}
          />
        )}
      </CenteredBox>
    </Badge>
  );
}

export type ProfileUploadContainerProps = {
  control: UseFormReturn<any>["control"];
  watch: UseFormReturn<any>["watch"];
  name?: string;
  avatarSize?: number;
};
