import { Box, Toolbar } from "@mui/material";
import { CLOSE_SIDEBAR_ON } from "@Constants/app";
import { AppBar } from "./Styles";
import Settings from "./Settings";
import { AppLogoContainer } from "../AuthenticatedAppRoot";

export default function Topbar() {
  return (
    <AppBar>
      <Box
        component={Toolbar}
        width="100%"
        justifyContent={{
          xs: "space-between",
          [CLOSE_SIDEBAR_ON]: "flex-end",
        }}
      >
        <AppLogoContainer />
        <Settings />
      </Box>
    </AppBar>
  );
}
