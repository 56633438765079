import {
  UploadedFiles,
  checkFileValidity,
  fileUploadCall,
  handleChangeUploadingState,
  makeBase64,
} from "@Services/fileUploadService";

import {
  Box,
  BoxProps,
  CircularProgress,
  FormHelperText,
  FormLabel,
  Typography,
  styled,
} from "@mui/material";
import { useMemo, useRef } from "react";
import { toast } from "react-toastify";
import UploadIcon from "@Assets/icons/Upload.svg";
import { ControllerRenderProps, FieldValues } from "react-hook-form";
import { FileTypeEnums } from "@Constants/enums";
import { Article } from "@mui/icons-material";
import { CenteredBox } from "@Theme/GeneralStyledComponents";
import useModal from "@Hooks/useModal";
import ImageCropperModal, {
  CropperModalDataType,
  CropperModalProps,
} from "@Components/Modal/ImageCropperModal";

function FileUpload({
  children,
  allowedFileTypes,
  limit,
  value = [],
  disabled,
  disabledMessage = "",
  onChange,
  name,
  showDefault = true,
  defaultComponentProps,
  error,
  multiple,
  uploadContainerProps,
  uploadOnServer = true,
  allowOnly,
  inputRef,
  showCropper,
  ...rest
}: FileUploadTypes) {
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const { openModal, Modal } = useModal<
    CropperModalDataType,
    Omit<CropperModalProps, "data">
  >(ImageCropperModal, {
    handleUploadFiles,
  });

  function handleFilesChange(files: any) {
    const filesInArray: File[] = Object.values(files ?? {});
    if (uploadRef.current) {
      uploadRef.current.value = "";
    }

    const isValid = checkFileValidity({
      filesInArray,
      value: value,
      limit,
      allowedFileTypes,
      allowOnly,
    });
    if (!isValid) return;

    const withUrlFiles = makeBase64(filesInArray);

    if (showCropper) {
      return openModal({
        files: withUrlFiles,
        filesInArray,
      });
    }

    handleUploadFiles(withUrlFiles, filesInArray);
  }

  function handleUploadFiles(
    withUrlFiles: ReturnType<typeof makeBase64>,
    filesInArray: File[]
  ) {
    if (uploadOnServer) {
      const currentValues = [
        ...withUrlFiles.map((file) => ({
          ...file,
          uploading: true,
        })),
        ...value,
      ] as UploadedFile[];

      filesInArray.map(async (file, id) => {
        const data = await fileUploadCall(file);
        handleChangeUploadingState({
          id: id,
          value: [...currentValues],
          onChange,
          data,
        });
      });

      onChange(currentValues);
    } else {
      const files = [...withUrlFiles, ...value] as UploadedFile[];
      onChange(files);
    }
  }

  function handleDrop(e: React.DragEvent<HTMLDivElement>) {
    handleErrorWhenDisabled();
    preventDefault(e);
    handleFilesChange(e.dataTransfer.files);
  }

  function handleErrorWhenDisabled() {
    if (disabled && disabledMessage) return toast.error(disabledMessage);
  }

  const { isDisabled, cursor } = useMemo(() => {
    const isDisabled =
      disabled ?? (value?.length >= (limit ?? 0) && Boolean(limit));

    return {
      isDisabled,
      cursor: isDisabled ? "not-allowed" : "pointer",
    };
  }, [disabled, value?.length, limit]);

  function fileInputOnChange({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) {
    handleFilesChange(files);
  }

  function handleClickUpload() {
    uploadRef.current?.click();
  }

  return (
    <Box flex={1}>
      {Boolean(rest.label) && (
        <FormLabel sx={{ color: "text.primary", mb: 0.5, display: "block" }}>
          {rest.label}
        </FormLabel>
      )}
      <Box
        {...uploadContainerProps}
        onDragOver={preventDefault}
        onDrop={handleDrop}
        onClick={handleErrorWhenDisabled}
      >
        <Box
          {...rest}
          accept={allowedFileTypes?.join(",")}
          component="input"
          disabled={isDisabled}
          multiple={multiple}
          onChange={fileInputOnChange}
          type="file"
          hidden
          id={name}
          ref={uploadRef}
        />

        <Box
          onClick={handleClickUpload}
          sx={{
            cursor,
          }}
        >
          {children}
          {showDefault && (
            <DefaultFileUpload
              isMultiple={multiple}
              value={value}
              {...defaultComponentProps}
            />
          )}
        </Box>
      </Box>
      <FormHelperText error> {error}</FormHelperText>
      {Modal}
    </Box>
  );
}
export default FileUpload;

export function DefaultFileUpload({
  title = "Drag and drop your files here",
  Icon = UploadIcon,
  value,
  isMultiple,
}: DefaultComponentProps) {
  const firstFile = value[0];

  return (
    <>
      <Box
        border="1px solid"
        borderRadius={1.5}
        borderColor="custom.textFieldBorder"
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        {Boolean(firstFile) ? (
          <RenderMedia file={firstFile} isFirst />
        ) : (
          <Box mb={2} textAlign="center">
            <Box p={2} textAlign="center">
              {title}
            </Box>
            <Icon />
          </Box>
        )}
      </Box>
      {isMultiple && (
        <Box
          mt={2}
          display="flex"
          gap={2}
          flexWrap="wrap"
          sx={{ pointerEvents: "none" }}
        >
          {value.slice(1).map((file) => (
            <RenderMedia key={file.id} file={file} />
          ))}
        </Box>
      )}
    </>
  );
}
function RenderMedia({
  file,
  isFirst = false,
}: {
  file: UploadedFile;
  isFirst?: boolean;
}) {
  let item = null;
  switch (file.fileType) {
    case FileTypeEnums.Image:
      item = <Media isFirst={isFirst} component="img" src={file.url} />;
      break;
    case FileTypeEnums.Video:
      item = <Media isFirst={isFirst} component="video" src={file.url} />;
      break;
    case FileTypeEnums.Application:
      item = (
        <Media isFirst={isFirst} flexDirection="column">
          <Article sx={{ fontSize: 50 }} />
          <Typography>{file.name}</Typography>
        </Media>
      );
      break;
    default:
  }

  return (
    <CenteredBox position="relative" width={isFirst ? "100%" : "auto"}>
      {item}
      {file.uploading && (
        <>
          <Box component={CircularProgress} position="absolute" zIndex={1} />
          <Box
            bgcolor="common.black"
            width="100%"
            height="100%"
            sx={{ opacity: 0.5 }}
            borderRadius={isFirst ? 1.5 : 4}
            position="absolute"
          />
        </>
      )}
    </CenteredBox>
  );
}

const Media = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isFirst",
})<{ isFirst?: boolean }>(({ isFirst, theme: { palette, spacing } }) => ({
  ...(!isFirst
    ? {
        border: "1px solid",
        borderColor: palette.primary.main,
        borderRadius: spacing(1.5),
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: 100,
        height: 100,
      }
    : {
        width: "100%",
        height: "100%",
        borderRadius: spacing(0.75),
      }),
  objectFit: "cover",
}));

function preventDefault(e: React.DragEvent<HTMLDivElement>) {
  e.preventDefault();
}

export type DefaultComponentProps = {
  title?: string;
  Icon?: React.ComponentType<any>;
  showInContainer?: boolean;
  isMultiple?: boolean;
  value: UploadedFile[];
};

export type FileUploadTypes = {
  label?: string;
  name: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  accept?: string;
  error: string;
  showCropper?: boolean;
  multiple?: boolean;
  showDefault?: boolean;
  uploadContainerProps?: BoxProps;
  defaultComponentProps?: Omit<DefaultComponentProps, "value">;
  children?: string | JSX.Element | JSX.Element[];
  disabled?: boolean;
  disabledMessage?: string;
  limit?: number;
  allowedFileTypes?: string[];
  value: UploadedFiles[];
  onChange: ControllerRenderProps<FieldValues, any>["onChange"];
  uploadOnServer?: boolean;
  allowOnly?: string;
};
export type FileType = "image" | "video" | "application";
export type UploadedFile = {
  url: string;
  file: File;
  name: string;
  id?: string;
  size: string;
  progress?: number;
  MediaId: number;
  error?: boolean;
  uploading?: boolean;
  fileType?: FileType;
};
