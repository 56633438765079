import React, { MutableRefObject } from "react";
import Modal, { ModalRefType } from "./Modal";
import { Box, Typography } from "@mui/material";

type ConfirmationModalProps = {
  onSuccess: () => void;
  modalRef: MutableRefObject<ModalRefType>;
  title: string;
  description: string;
  okText: string;
  cancelText: string;
  icon: React.ReactNode;
};

function ConfirmationModal({
  onSuccess,
  modalRef,
  title,
  description,
  okText,
  cancelText,
  icon,
}: ConfirmationModalProps) {
  return (
    <Modal
      modalRef={modalRef}
      closeOnSuccess
      okText={okText}
      cancelText={cancelText}
      onSuccess={onSuccess}
      showClose
      customBody={
        <Box mb={3} textAlign="center">
          <Typography sx={{ fontWeight: "500", fontSize: 20, mb: 4 }}>
            {title}
          </Typography>
          {icon}
          <Typography
            fontWeight={400}
            fontSize={18}
            mt={4}
            color="text.secondary"
            mx={4}
          >
            {description}
          </Typography>
        </Box>
      }
    />
  );
}

export default ConfirmationModal;
