import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { Controller } from 'react-hook-form';

const InputField = ({ control, name, label, ...props }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => (
      <TextField
        {...field}
        {...props}
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {label}:
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiInputBase-root': {
            paddingLeft: 1,
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 1000px white inset',
              WebkitTextFillColor: 'inherit',
            },
          },
          '& .MuiInputAdornment-root': {
            marginRight: 1,
            color: 'text.secondary',
          },
        }}
      />
    )}
  />
);

export default InputField;
