import { STORAGE_KEYS } from "@Constants/storage";
import { getItem, setItem } from "@Services/storageService";
import { create } from "zustand";

interface IAuthStore {
  isAuth: boolean;
  setUserAuthentication: (isAuth?: boolean, data?: any) => void;
}

export const useAuthStore = create<IAuthStore>((set) => ({
  isAuth: Boolean(getItem(STORAGE_KEYS.AUTH_TOKEN)),

  setUserAuthentication: (isAuth: boolean = false, data?: any) => {
    if (data) {
      setItem(STORAGE_KEYS.LOGIN_DATA, data);
      setItem(STORAGE_KEYS.AUTH_TOKEN, data);
      setItem(STORAGE_KEYS.REFRESH_TOKEN, data);
    }
    set({ isAuth });
  },
}));
