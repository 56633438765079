import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';

const chapterCounts: { [key: string]: number } = {
  Genesis: 50,
  Exodus: 40,
  Leviticus: 27,
};

function ChaptersList() {
  const { book } = useParams<{ book: string }>();
  const numChapters = chapterCounts[book] || 1;
  const chapters = Array.from({ length: numChapters }, (_, i) => i + 1);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {book}
      </Typography>
      <Typography variant="h6" gutterBottom>
        Select a Chapter
      </Typography>
      <List>
        {chapters.map((chapter) => (
          <ListItem key={chapter} disablePadding>
            <ListItemButton component={Link} to={`/books/${book}/chapter/${chapter}`}>
              <ListItemText primary={`Chapter ${chapter}`} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default ChaptersList;