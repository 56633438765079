import React from 'react';
import crossReferences from '../../data/crossRefrences.json';
import { Typography, List, ListItem, ListItemText } from '@mui/material';

type Reference = {
  reference: string;
  text: string;
};

type Props = {
  verse: string;
};

function VerseReferences({ verse }: Props) {
  const references: Reference[] = crossReferences[verse] || [];

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        References for {verse}
      </Typography>
      {references.length > 0 ? (
        <List>
          {references.map((ref, index) => (
            <ListItem key={index} alignItems="flex-start">
              <ListItemText
                primary={ref.reference}
                secondary={ref.text}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <Typography>No references available.</Typography>
      )}
    </div>
  );
}

export default VerseReferences;