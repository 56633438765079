import { ToastContainer as ToastifyContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ToastContainer() {
  return (
    <ToastifyContainer
      style={{ zIndex: 9999999 }}
      toastStyle={{
        backgroundColor: "#005365",
        fontFamily: "Sofia Sans, sans-serif",
        color: "white",
      }}
      progressStyle={{
        background: "#EEEEEE",
      }}
      position="bottom-left"
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
}
