import { GridColDef } from "@mui/x-data-grid";
import { theme } from "../theme/GlobalStyles";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

import {
  APP_NAME,
  CLOSE_SIDEBAR_ON,
  DRAWER_WIDTH_NUMBER,
  TABLE_MIN_WIDTH_BREAKPOINT,
  TABLE_MIN_WIDTH_ON_MOBILE,
} from "@Constants/app";

function columnNormalizerForDataGrid(
  columns: GridColDef[],
  dataToInclude: {},
  resizedColumnWidth: {}
): GridColDef[] {
  return columns.map((colData) => {
    return {
      flex: 1,
      sortable: true,
      ...colData,
      ...resizedColumnWidth,
      valueFormatter: ({ value }: { value: any }) =>
        replaceNullWithPlaceholder(value, "--"),
      ...dataToInclude,
    };
  });
}

export function setPageTitle(pathname: string) {
  if (pathname === "/") {
    pathname = "| Dashboard";
  }

  pathname = pathname
    .split("/")
    .map((route) =>
      route
        .split("-")
        .map((word) => `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
        .join(" ")
    )
    .join(" | ");
  document.title = `${APP_NAME}  ${pathname} `;
}

export function getTableColumnWidth(columns: GridColDef[]) {
  const widths = {
    maxWidth: 300,
    minWidth: 1,
  };

  const breakPoints = theme.breakpoints.values;
  const greaterThanSideBarClose =
    window.innerWidth >= breakPoints[CLOSE_SIDEBAR_ON];
  const normalizedInnerWidth = window.innerWidth - 48;

  if (greaterThanSideBarClose) {
    widths.maxWidth =
      (normalizedInnerWidth - DRAWER_WIDTH_NUMBER) / columns.length;
  } else {
    widths.maxWidth = normalizedInnerWidth / columns.length;
  }

  if (window.innerWidth < TABLE_MIN_WIDTH_BREAKPOINT) {
    widths.maxWidth = 300;
    widths.minWidth = TABLE_MIN_WIDTH_ON_MOBILE;
  }

  return widths;
}
function replaceNullWithPlaceholder(value: any, replaceBy?: string) {
  if (!value) {
    return replaceBy ?? "N/A";
  }

  return value;
}

function valueNormalizer(
  val: number | string,
  character: string = "$"
): string {
  let formattedValue: string;

  if (typeof val === "number") {
    const roundedValue = Math.round(val * 100) / 100;
    formattedValue = roundedValue.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  } else {
    formattedValue = val;
  }

  return `${character}${formattedValue}`;
}

function formatCurrency(number: number) {
  return "$" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const convertDateTime = (dateTimeString: string): string => {
  if (!dateTimeString) return 'Invalid Date';
  
  const parsed = dayjs(dateTimeString);
  if (!parsed.isValid()) return 'Invalid Date';
  
  return parsed.format('YYYY-MM-DD HH:mm:ss');
};

function goBack() {
  window.history.back();
}

function getPageSizeOptions(numberOfPages: number) {
  return Array.from({ length: Math.min(numberOfPages, 10) }, (_, i) => {
    const value = `${10 * (i + 1)}`;
    return {
      label: value,
      value,
    };
  });
}

function appendZero(value: number) {
  return value.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}

function getTimeFromSeconds(seconds: number) {
  let hours = Math.floor(seconds / 3600);
  seconds = seconds % 3600;
  let mins = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);

  return {
    hours: appendZero(hours),
    mins: appendZero(mins),
    seconds: appendZero(seconds),
  };
}

function createDynamicUrl(
  url: string,
  params: { [key: string]: string },
  startIdentifier: string = "{",
  endIdentifier: string = "}"
) {
  return Object.keys(params).reduce((acc, key) => {
    return acc.replace(`${startIdentifier}${key}${endIdentifier}`, params[key]);
  }, url);
}

export {
  columnNormalizerForDataGrid,
  replaceNullWithPlaceholder,
  valueNormalizer,
  formatCurrency,
  getPageSizeOptions,
  goBack,
  getTimeFromSeconds,
  createDynamicUrl,
};
