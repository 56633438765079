import { BASE_URLS, HTTP_STATUS } from "@Constants/api";
import { API_ROUTES } from "@Constants/apiUrls";
import { STORAGE_KEYS } from "@Constants/storage";

import { clearAllData, getItem, setItem } from "@Services/storageService";
import {
  UseMutationOptions,
  UseQueryOptions,
  onlineManager,
} from "@tanstack/react-query";
import { ApiResponse, PROBLEM_CODE, create } from "apisauce";
import axios from 'axios';

export const BASE_URL = BASE_URLS.DEV;

export const UNAUTHORIZED_URLS = [
  API_ROUTES.TOKENS.LOGIN,
  API_ROUTES.TOKENS.REFRESH,
  API_ROUTES.ADMIN.FORGOT_PASSWORD,
  API_ROUTES.ADMIN.RESET_PASSWORD,
  API_ROUTES.ADMIN.VERIFY_TOKEN,
];

const apiSauceInstance = create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

async function requestApi<T>(
  url: string,
  method: "get" | "post" | "delete" | "put" | "patch",
  queryParams?: any,
  config?: any
) {
  const response = await apiSauceInstance[method]<any>(
    url,
    queryParams,
    config
  );

  const mutatedResponse: TErrorResponse | TSuccessResponse<T> = {
    ok: response?.ok,
    data: response?.data,
    status: response?.status ?? 0,
    problem: response?.problem,
    url: response?.config?.url ?? "",
  };
  return mutatedResponse;
}

apiSauceInstance.addAsyncRequestTransform(async ({ headers, url = "" }) => {
  if (UNAUTHORIZED_URLS.includes(url)) return;
  headers!["Authorization"] = `Bearer ${getItem(STORAGE_KEYS.AUTH_TOKEN)}`;
});

let isRefreshing = false;

async function refreshToken(response: ApiResponse<any, any>) {
  if (
    response?.status == HTTP_STATUS.UNAUTHORIZED &&
    !response?.config?.url?.includes(API_ROUTES.TOKENS.REFRESH) &&
    !response?.config?.url?.includes(API_ROUTES.TOKENS.LOGIN)
  ) {
    onlineManager.setOnline(false);

    if (!isRefreshing) {
      isRefreshing = true;

      const token = getItem(STORAGE_KEYS.AUTH_TOKEN);
      const refreshToken = getItem(STORAGE_KEYS.REFRESH_TOKEN);

      try {
        const res = await apiSauceInstance.post<any>(
          API_ROUTES.TOKENS.REFRESH,
          {
            token,
            refreshToken,
          }
        );
        if (!res.ok) {
          clearAllData();
          onlineManager.setOnline(true);
        } else {
          setItem(STORAGE_KEYS.AUTH_TOKEN, res?.data?.token);
          setItem(STORAGE_KEYS.REFRESH_TOKEN, res?.data?.refreshToken);
          onlineManager.setOnline(true);
        }
      } catch (e) {
        console.error(e, "error while refreshing token");
        clearAllData();
      } finally {
        isRefreshing = false;
        onlineManager.setOnline(true);
      }
    }
  }
}

apiSauceInstance.addResponseTransform(refreshToken);

export const ERROR_TYPES = {
  NETWORK_ERROR: "Network not available",
  SERVER_ERROR: "Something went wrong",
  TIMEOUT_ERROR: "Server didn't respond in time",
};

type MutatedResponse<TData, TOk> = {
  ok: TOk;
  data: TData;
  status: number;
  problem: PROBLEM_CODE | null;
  url: string;
};

type TError = {
  body: null;
  metadata: {
    type: string;
    statusCode: number;
    message: string;
    validationErrors?: { [key: string]: string }[];
    errorId: null;
  };
};

type TErrorResponse = MutatedResponse<TError, false>;
type TSuccessResponse<T> = MutatedResponse<T, true>;

export type TQueryOptions<TData> = Omit<
  UseQueryOptions<TData, TErrorResponse, TData>,
  "queryKey" | "queryFn"
>;

export type TMutationOptions<TData, TVariables> = UseMutationOptions<
  TData,
  TErrorResponse,
  TVariables
>;

export default requestApi;

const API_BASE_URL = '/api';

export const chatWithAI = async (message) => {
  const response = await axios.post(`${API_BASE_URL}/chat`, { message });
  return response.data;
};

