import { Navigate, createBrowserRouter } from "react-router-dom";
import { NavigationRoutes } from "./NavigationRoutes";
import AuthenticatedAppRoot from "@Pages/AppScreens/Layout/AuthenticatedAppRoot";
import { AddVerse, VerseDetail, Dashboard } from "./LazyImports";
import BooksList from '../components/BookList/BookList';
import ChaptersList from '../components/ChapterList/ChapterList';
import ChapterView from '../components/ChapterView/ChapterView';

export const AppRouter = createBrowserRouter([
  {
    path: '/',
    element: <AuthenticatedAppRoot />,
    children: [
      {
        index: true,
        element: <Dashboard />,
      },
      {
        path: NavigationRoutes.APP_ROUTES.ADD_VERSE,
        element: <AddVerse />,
      },
      {
        path: NavigationRoutes.APP_ROUTES.VERSE_DETAIL,
        element: <VerseDetail />,
      },
      {
        path: NavigationRoutes.APP_ROUTES.BOOKS_LIST,
        element: <BooksList />,
      },
      {
        path: NavigationRoutes.APP_ROUTES.CHAPTERS_LIST,
        element: <ChaptersList />,
      },
      {
        path: NavigationRoutes.APP_ROUTES.CHAPTER_VIEW,
        element: <ChapterView />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to={NavigationRoutes.APP_ROUTES.DASHBOARD} replace />,
  },
]);
