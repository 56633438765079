const CONTROLLERS = {
  tokensController: "admin/tokens",
  documentController: "document",
  adminController: "admin",
  personalController: "admin/personal",
};

export const API_ROUTES = {
  TOKENS: {
    LOGIN: `${CONTROLLERS.tokensController}/access-token`,
    REFRESH: `${CONTROLLERS.tokensController}/refresh-token`,
    LOGOUT: `${CONTROLLERS.tokensController}/log-out`,
  },

  ADMIN: {
    FORGOT_PASSWORD: `${CONTROLLERS.adminController}/forgot-password`,
    RESET_PASSWORD: `${CONTROLLERS.adminController}/reset-password`,
    RESEND_FORGOT_PASSWORD: `${CONTROLLERS.adminController}/resend-forgot-password`,
    VERIFY_TOKEN: `${CONTROLLERS.adminController}/verify-token`,
    CHANGE_PASSWORD: `${CONTROLLERS.adminController}/change-password`,
  },

  DOCUMENT: {
    INIT_UPLOAD: `${CONTROLLERS.documentController}/upload-initialize-document`,
  },
  PERSONAL: {
    PROFILE: `${CONTROLLERS.personalController}/profile`,
  },
};
