import { API_MODES, HTTP_STATUS } from "@Constants/api";
import requestApi, { UNAUTHORIZED_URLS } from "@Services/apiService";
import { createDynamicUrl } from "@Utils/utils";
import { toast } from "react-toastify";

export async function request<T>({
  url,
  method,
  params = {},
  config,
  showToast = true,
}: RequestTypes) {
  const response =
    (await requestApi<T>(
      createDynamicUrl(url, params),
      method,
      params,
      config
    )) || {};
  if (response.ok) {
    return response;
  } else {
    const error = response.data.metadata;

    if (
      error.statusCode !== HTTP_STATUS.UNAUTHORIZED ||
      UNAUTHORIZED_URLS.includes(response.url)
    ) {
      showToast && toast.error(error?.message || "Something went wrong");
    }

    throw new Error(error?.message, { cause: error.statusCode });
  }
}

type RequestTypes = {
  url: string;
  method: API_MODES;
  params?: {};
  config?: {};
  showToast?: boolean;
};
