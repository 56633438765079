import FullPageLoader from "@Components/FullPageLoader/FullPageLoader";
import { DRAWER_WIDTH } from "@Constants/app";

import { Box, styled } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

export default function Content() {
  return (
    <Suspense fallback={<FullPageLoader zIndex={-1} />}>
      <Main component="main">
        <AnimatePresence mode="wait">
          <Outlet />
        </AnimatePresence>
      </Main>
    </Suspense>
  );
}

const Main = styled(Box)(({ theme: { spacing, palette } }) => ({
  background: palette.custom.background,
  padding: spacing(3),
  marginTop: spacing(8),
  height: "max-content",
  width: `calc(100% - ${DRAWER_WIDTH})`,
  flexGrow: 1,
}));
