import { createBrowserRouter, Navigate } from "react-router-dom";
import { NavigationRoutes } from "./NavigationRoutes";
import { Login, OnBoarding, SignUp } from "./LazyImports";

export const AuthenticationRouter = createBrowserRouter([
  {
    path: NavigationRoutes.AUTH_ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: NavigationRoutes.AUTH_ROUTES.REGISTER,
    element: <SignUp />,
  },
  {
    path: NavigationRoutes.AUTH_ROUTES.ONBOARDING,
    element: <OnBoarding />,
  },

  {
    path: "*",
    element: <Navigate to={NavigationRoutes.AUTH_ROUTES.LOGIN} />,
  },
]);
