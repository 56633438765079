import { lazy } from "react";

// AUTH
const Login = lazy(() => import("@Pages/AuthScreens/Login/Login"));
const SignUp = lazy(() => import("@Pages/AuthScreens/SignUp/SignUp"));

const OnBoarding = lazy(
  () => import("@Pages/AuthScreens/Onboarding/Onboarding")
);

// APP
const Dashboard = lazy(() => import("@Pages/AppScreens/Dashboard/Dashboard"));
const AddVerse = lazy(
  () => import("@Pages/AppScreens/AddVerse/AddVerse")
);
const VerseDetail = lazy(
  () => import("@Pages/AppScreens/VerseDetail/VerseDetail")
);

export { Dashboard, Login, SignUp, OnBoarding, AddVerse, VerseDetail };
