import React from 'react';
import { Link } from 'react-router-dom';
import { List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';

const optionNames = [
  'Book Title 1',
  'Book Title 2',
  'Book Title 3',
];

function BooksList() {
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Select a Book
      </Typography>
      <List>
        {optionNames.map((book) => (
          <ListItem key={book} disablePadding>
            <ListItemButton component={Link} to={`/books/${book}`}>
              <ListItemText primary={book} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default BooksList;