export const NavigationRoutes = {
  AUTH_ROUTES: {
    LOGIN: "/login",
    REGISTER: "/register",
    OTP: "/otp",
    FORGOT_PASSWORD: "/forgot-password",
    RESET_PASSWORD: "/reset-password",
    VERIFY_EMAIL: "/verify-email",
    CHANGE_PASSWORD: "/change-password",
    ONBOARDING: "/onboarding",
  },

  APP_ROUTES: {
    DASHBOARD: "/",
    ADD_VERSE: "/add-verse",
    VERSE_DETAIL: "/verse-detail/:id",
    BOOKS_LIST: "/books",
    CHAPTERS_LIST: "/books/:book",
    CHAPTER_VIEW: "/books/:book/chapter/:chapter",
  },
};
